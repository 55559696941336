const getLngs = (option: any) => {
    const dValue = option.northEast[0] - option.southWest[0];
    const yALatlng = dValue / option.yAccuracy;
    const list = [];
    for (let i = 0; i < option.yAccuracy + 1; i++ ) {
        list.push(option.southWest[0] + i * option.step);
    }
    return list;
}
const getLats = (option: any) => {
    const dValue = option.northEast[1] - option.southWest[1];
    const xALatlng = dValue / option.xAccuracy;
    const list = [];
    for (let i = 0; i < option.xAccuracy + 1; i++ ) {
      list.push(option.southWest[1] + i * option.step);
    }
    return list;
}
const drawHLine = (lngs: any, lats: any) => {
    const latlngArrs = [];
    for (let i = 0, len = lngs.length; i < len ; i++) {
        const latlngs = [];
        for (let j = 0, jlen = lats.length; j < jlen ; j++) {
            latlngs.push([lngs[i], lats[j]]);
        }
        latlngArrs.push(latlngs)
    }
    return latlngArrs
}
const drawVLine = (lngs: any, lats: any) => {
    const latlngArrs = [];
    for (let i = 0, len = lats.length; i < len ; i++) {
        const latlngs = [];
        for (let j = 0, jlen = lngs.length; j < jlen ; j++) {
            latlngs.push([lngs[j], lats[i]]);
        }
        latlngArrs.push(latlngs)
    }
    return latlngArrs
}
const createGrid = (minMaxLonLat: any, intervalNum: number) => {
    const {maxLon, minLon, maxLat, minLat} = minMaxLonLat
    const xValue = Math.floor((maxLon - minLon) / intervalNum / 0.025)
    const yValue = Math.floor((maxLat - minLat) / intervalNum / 0.025)
    const options = {
      northEast: [maxLat, maxLon],
      southWest: [minLat, minLon],
      xAccuracy: xValue, // 格子数目
      yAccuracy: yValue, // 格子数目
      opacity: 1,
      step: 0.025 * intervalNum,
    }
    // netGrid(this.map, options,this.gridDataGroup )
    const lngs = getLngs(options);
    const lats = getLats(options);
    const HLine = drawHLine(lngs, lats);
    const VLine = drawVLine(lngs, lats);
    return [...HLine, ...VLine]
}
export default createGrid
