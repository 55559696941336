<template>
    <div style="width: 100%;height: 100%;">
        <div class="current">
            <span class="current-time"><span>{{timeRange}}</span>格点预报</span>
            <div class="current-location">
                <img src="/static/image/forecast/location.png" alt="">
                <div>
                    当前位置：{{location.formatted_address}}
                </div>
            </div>
            <img class="current-close" src="/static/image/forecast/close.png" alt="" @click="$emit('close')">
        </div>
        <div class="table">
            <Spin v-if="loading" fix/> 
            <!--表头-->
            <div class="item">
                <div class="value">预报时间</div>
                <div class="value">天气</div>
                <div class="value">天气描述</div>
                <div class="value">温度(℃)</div>
                <div class="value">降水量(mm)</div>
            </div>
            <!--内容-->
            <div v-for="(item,typeIndex) in forecastArray" :key="typeIndex" class="item">
                <div class="value">
                    <div>
                        <div>{{item.date}}</div>
                        <div v-if="item.time">{{item.time}}</div>
                    </div>
                </div>
                <div class="value">
                    <img class="icon" :src="item.wpimg">
                </div>
                <div class="value">{{item.wpdesc}}</div>
                <div class="value" v-if="item.t2m">{{item.t2m}}</div>
                <div class="value" v-else>{{item.tmin24}}~{{item.tmax24}}</div>
                <div class="value" v-if="timeId === 'hour'">{{item.rain}}</div>
                <div class="value" v-else>{{item.rain24}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment/moment'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        lng: {type: Number},
        lat: {type: Number},
        timeId: {type: String},
        forecastArray: {type: Array},
    },
    data() {
        return{
            timeRange: '',
            location: {},
            loading: true,
        }
    },
    computed: {
        lngLat() {
            const { lng, lat } = this
            return { lng, lat }
        },
    },
    watch: {
        lngLat() {
            this.getLocation()
        },
        timeId() {
            this.getTimerange()
        },
    },
    mounted() {
        this.getTimerange()
        this.getLocation()
    },
    methods: {
        getTimerange() {
            if (this.timeId === 'hour') {
                const start = moment(new Date(), 'YYYYMMDDHHmmss').format('M月D日HH时')
                const end = moment(new Date(), 'YYYYMMDDHHmmss').add(11, 'hours').format('M月D日HH时')
                this.timeRange = `${start}-${end}`
            } else {
                const start = moment(new Date(), 'YYYYMMDDHHmmss').add(1, 'days').format('M月D日')
                const end = moment(new Date(), 'YYYYMMDDHHmmss').add(8, 'days').format('M月D日')
                this.timeRange = `${start}-${end}`
            }
        },
        getLocation() {
            const params = {
                postStr: `{'lon':${this.lng},'lat':${this.lat},'ver':1}`,
                type: 'geocode',
                tk: '3ea5ad4a3c9e978735800163ef36217a',
            }
            this.loading = true
            this.location = {}
            request.get('/geocoder', params).then((data) => {
                this.loading = false
                if (!data || !data.result) {
                    return
                }
                this.location = data.result
            })
        },
    },
})
</script>
<style lang="scss" scoped>
.current{
    // background: pink;
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 10px;
    .current-time{
        font-size: 14px;
        span{
            color: #FFFC00;
        }
    }
    .current-location{
        // background: pink;
        flex: 1;
        display: flex;
        margin: 0 10px;
        img{
            display: block;
            width: 17px;
            height: 21px;
            margin-right: 3px;
        }
    }
    .current-close{
        display: block;
        width: 25px;
        height: 25px;
    }
}
.table {
    // background: plum;
    display: flex;
    $border:1px solid rgba(220, 219, 219, 1);
    color: #fff;
    .item:first-child {
        width: 100px;
        border-left: $border;
    }
    .item {
        border-right: $border;
        .value {
            text-align: center;
            border-bottom: $border;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
        }
        .value:first-child {
            border-top: $border;
            height: 50px;
        }
        .value:nth-child(2) {
            height: 40px;
        }
        .icon {
            width: 30px;
        }
    }
}
.ivu-spin-fix {
    background-color: rgba($color: #000000, $alpha: .2);
    border-radius: 8px;
}
</style>
