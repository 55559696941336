const jsons: any = {
  610115: require('@/assets/610115.json'),
}

const polygon = (onlyKey: string|null = null) => {
  const data: number[][][] = []
  for (const key in jsons) {
    if (!jsons[key] || (onlyKey != null && onlyKey !== key)) {
      continue
    }
    const json = jsons[key][0]
    if (!json || json.length === 0) {
      continue
    }
    const array: number[][] = []
    for (const j of json) {
        array.push([j[1], j[0]])
    }
    data.push(array)
  }
  return data
}

export default { polygon }
