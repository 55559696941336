
const INTERVALVALUE = 0.025
const zoomarray = [1, 2, 3, 5, 8, 12]
/**
 * 根据最大值和最小值，计算满足不同缩放比例的间隔的最大值（经纬度）
 * @param max
 * @param min
 */
const calcRelativeMax = (max: number, min: number): number => {
  const maxArray: number[] = zoomarray.map((z: number) => calcByZoom(max, min, z))
  // console.log(maxArray)
  return Math.max(...maxArray)
}
/**
 * 按固定比例计算最大值（经纬度）
 * @param max
 * @param min
 * @param zoom
 */
const calcByZoom = (max: number, min: number, zoom: number): number => {
  return min + calcIntervalNum(max, min, zoom) * INTERVALVALUE * zoom
}
/**
 * 计算实际间隔数
 * @param max
 * @param min
 * @param zoom
 */
const calcIntervalNum = (max: number, min: number, zoom: number): number => {
  const d = max - min // 最大到最小的差值
  const intervalSub = INTERVALVALUE * zoom // 根据缩放比例计算的间隔
  const increment = d % intervalSub > 0 ? 1 : 0
  return d / intervalSub + increment
}
export {calcRelativeMax, calcByZoom, calcIntervalNum}
