<template>
    <div class="timeParam">
        <div class="timeParamItem" v-for="(item,index) in timeList" :key="index" 
            :class="{active:timeParamIndex==index,isDay:item.isDay}" 
            @click="timeChange(index)">
            {{item.text}}
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment/moment'
export default Vue.extend({
    props: ['timeId'],
    watch: {
        timeId() {
            // console.log(this.timeId)
            this.timeParamIndex = 0
            if (this.timeId === 'hour') {
                this.getHourTimeNode()
                this.$emit('currentTime', this.timeList[this.timeParamIndex])
            } else {
                this.getDayTimeNode()
                this.$emit('currentTime', this.timeList[this.timeParamIndex])
            }
        },
    },
    data() {
        return {
            timeList: [],
            timeParamIndex: 0,
        }
    },
    mounted() {
        this.getHourTimeNode()
        this.$emit('currentTime', this.timeList[this.timeParamIndex])
    },
    methods: {
        getHourTimeNode() {
            this.timeList = []
            const dates = [];
            for (let m = 0; m < 24;) {
                let days = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                days = days.getDate(); // 获取当前日期中月的天数
                const currentHour = new Date().getHours();
                const currentDate = new Date().getDate();
                const currentMonth = new Date().getMonth() + 1;
                const currentYear = new Date().getFullYear();
                let hour = currentHour + m % 24;
                let date = currentDate;
                let month = currentMonth;
                let year = currentYear;
                if (hour > 23) {
                    date += parseInt(m / 24, 10) + 1;
                    hour -= 24;
                } else {
                    date += parseInt(m / 24, 10);
                }
                if (date > days) {
                    month += 1;
                    date -= days;
                }
                if (month > 12) {
                    year += 1;
                    month -= 12;
                }
                month = month < 10 ? '0' + month : month;
                date = date < 10 ? '0' + date : date;
                hour = hour < 10 ? '0' + hour : hour;
                if (dates.includes(date)) {
                    this.timeList.push({id: year + '' + month + '' + date + '' + hour, text: hour + ':00'})
                } else {
                    dates.push(date);
                    this.timeList.push({id: year + '' + month + '' + date + '' + hour,
                     text: date + '日' + hour + ':00', isDay: true})
                }
                m += 1;
            }
            // console.log(this.timeList)
        },
        getDayTimeNode() {
            this.timeList = []
            const current = moment(new Date(), 'YYYYMMDDHHmmss').format('MM月DD日')
            const dates = [];
            for (let m = 1; m < 13;) {
                this.timeList.push({
                    id: moment(new Date(), 'YYYYMMDDHHmmss').add(m, 'days').format('YYYYMMDD'),
                    text: moment(new Date(), 'YYYYMMDDHHmmss').add(m, 'days').format('MM月DD日'),
                })
                m += 1;
            }
            // console.log(this.timeList)
        },
        timeChange(timeIndex) {
            this.timeParamIndex = timeIndex
            this.$emit('currentTime', this.timeList[timeIndex])
        },
    },
})
</script>
<style scoped>
.timeParam{
    background: #0A56A2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.timeParamItem{
    color: #fff;
    cursor: pointer;
}
.timeParamItem.isDay{
    font-weight: bold;
    color: #05a5f5;
    font-style: italic;
}
.timeParamItem.active{
    color: #000;
    background: #FED248;
    border-radius: 4px;
    padding: 5px;
    font-weight: bold;
}
</style>