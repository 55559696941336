<template>
    <div style="width: 100%;height: 100%;">
        <div class="current">
            <span></span>
            <img class="current-close" src="/static/image/forecast/close.png" alt="" @click="$emit('close')">
        </div>
        <Spin v-if="loading" fix/> 
        <div v-if="chartShow" id="mainChart" style="height:calc(100% - 25px);width:100% !important;"></div>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment/moment'
import request from '@/utils/request'
import VueDragResize from 'vue-drag-resize';
export default Vue.extend({
    props: {
        timeId: {type: String},
        forecastArray: {type: Array},
    },
    data() {
        return{
            loading: true,
            chartShow: true,
        }
    },
    watch: {
        timeId() {
            this.getData()
        },
        forecastArray() {
            this.getData()
        },
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            this.loading = true
            this.mapShow = false
            // console.log(this.timeId)
            // console.log(this.forecastArray)
            if (this.timeId === 'hour') {
                this.createHourChart(
                    this.forecastArray.map((e) => e.time),
                    this.forecastArray.map((e) => e.rain),
                    this.forecastArray.map((e) => e.t2m),
                )
            } else {
                this.createDayChart(
                    this.forecastArray.map((e) => e.date),
                    this.forecastArray.map((e) => e.rain24),
                    this.forecastArray.map((e) => e.tmax24),
                    this.forecastArray.map((e) => e.tmin24),
                )
            }
            this.loading = false
            await this.$nextTick()
            this.mapShow = true
        },
        createHourChart(data, value1, value2) {
            const myChart = this.$echarts.init(document.getElementById('mainChart')); // 基于准备好的dom，初始化echarts实例
            window.onresize = myChart.resize;  // 图表宽度自适应
            const colors = ['#3385FD', '#FF8500'];
            const option = {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff',
                        },
                    },
                },
                grid: {
                    x: 30,
                    y: 35,
                    x2: 30,
                    y2: 25,
                },
                legend: {
                    data: ['气温', '降水量'],
                    x: 'right',
                    padding: [8, 80, 0, 0],
                    textStyle: {
                        color: '#fff',
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        data,
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#fff',
                            // rotate: 30,
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '降水/mm',
                        nameTextStyle: {
                            color: '#fff',
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                    {
                        type: 'value',
                        name: '温度/℃',
                        nameTextStyle: {
                            color: '#fff',
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                ],
                series: [
                    {
                        name: '降水量',
                        type: 'bar',
                        barWidth: 15, // 柱图宽度
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {color: colors[0], fontSize: 16},
                                },
                            },
                            lineStyle: {
                                width: 1,
                            },
                        },
                        data: value1,
                    },
                    {
                        name: '气温',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {color: colors[1], fontSize: 16},
                                },
                                lineStyle: {
                                    width: 4,
                                },
                            },
                        },
                        data: value2,
                    },
                ],
            };
            myChart.setOption(option);
        },
        createDayChart(data, value1, value2, value3) {
            const myChart = this.$echarts.init(document.getElementById('mainChart')); // 基于准备好的dom，初始化echarts实例
            window.onresize = myChart.resize;  // 图表宽度自适应
            const colors = ['#1BB5A8', '#FF8500', '#3385FD'];
            const option = {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff',
                        },
                    },
                },
                grid: {
                    x: 30,
                    y: 35,
                    x2: 30,
                    y2: 25,
                },
                legend: {
                    data: ['最高温度', '最低温度', '降水量'],
                    x: 'right',
                    padding: [8, 80, 0, 0],
                    textStyle: {
                        color: '#fff',
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        data,
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#fff',
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '降水/mm',
                        nameTextStyle: {
                            color: '#fff',
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                    {
                        type: 'value',
                        name: '温度/℃',
                        nameTextStyle: {
                            color: '#fff',
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                    },
                ],
                series: [
                    {
                        name: '降水量',
                        type: 'bar',
                        barWidth: 15, // 柱图宽度
                        itemStyle: {
                            normal: {
                                label: {
                                show: true,
                                position: 'top',
                                textStyle: {color: colors[0], fontSize: 16},
                                },
                            },
                            lineStyle: {
                                width: 1,
                            },
                        },
                        data: value1,
                    },
                    {
                        name: '最高温度',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {color: colors[1], fontSize: 16},
                                },
                                lineStyle: {
                                    width: 4,
                                },
                            },
                        },
                        data: value2,
                    },
                    {
                        name: '最低温度',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {color: colors[2], fontSize: 16},
                                },
                                lineStyle: {
                                    width: 4,
                                },
                            },
                        },
                        data: value3,
                    },
                ],
            };
            myChart.setOption(option);
        },
    },
})
</script>
<style lang="scss" scoped>
.current{
    // background: pink;
    display: flex;
    justify-content: space-between;
    color: #fff;
    .current-close{
        display: block;
        width: 25px;
        height: 25px;
    }
}
#mainChart{
    // background: purple;
}
</style>
