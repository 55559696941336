





import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import mapMain from './dataComponents/mapMain.vue'

export default Vue.extend({
    components: {mapMain},
})
