






















































































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import timeTable from '../dataComponents/timeTable.vue'
import forecastTable from '../dataComponents/forecastTable.vue'
import forecastChart from '../dataComponents/forecastChart.vue'
import polygonService from '@/utils/polygonService'
import skyconText from '@/utils/baseData/skyconText'
import layerList from '@/views/monitor/dataService/layerList.json'
import weatherData from '@/views/monitor/dataService/weatherData'
import { calcRelativeMax, calcByZoom, calcIntervalNum } from '../dataService/refine_calc_util'
import getPolylineArray from '../dataService/getPolylineArray'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LMap, LFeatureGroup, LTileLayer, LPolygon, LMarker, LIcon, LPolyline } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import { array } from 'js-md5'
L.Icon.Default.imagePath = 'leaflet/dist/images/'
const options = {
  subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
  key: '3ea5ad4a3c9e978735800163ef36217a',
}
// const url1 = 'http://t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}'
// const url2 = 'http://t{s}.tianditu.com/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk={key}'
// const url3 = 'http://t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}',
// const url4 = 'http://t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}',
// const url1 = '/DataServer{s}?T=vec_w&X={x}&Y={y}&L={z}&tk={key}'
// const url2 = '/DataServer{s}?T=cva_w&X={x}&Y={y}&L={z}&tk={key}'
// const url3 = '/DataServer{s}?T=img_w&X={x}&Y={y}&L={z}&tk={key}'
// const url4 = '/DataServer{s}?T=cia_w&X={x}&Y={y}&L={z}&tk={key}'


const minMaxLonLat = {
  maxLon: 109.47782219,
  minLon: 109.057419213,
  maxLat: 34.66311654,
  minLat: 34.2651296,
}
export default Vue.extend({
  components: {
    LMap,
    LFeatureGroup,
    LTileLayer,
    LPolygon,
    LMarker,
    LIcon,
    LPolyline,
    timeTable,
    forecastTable,
    forecastChart,
  },
  data() {
    return {
      zoom: 10.8,
      center: [config.defaultStationObj.lat, config.defaultStationObj.lon],
      option: {
        zoomSnap: 0.2, attributionControl: false, zoomControl: false,
        dragging: true, scrollWheelZoom: true,
      },
      options,
      //  url1, url2, url3, url4,
      url1: 'http://t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}',
      url2: 'http://t{s}.tianditu.com/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk={key}',
      url3: 'http://t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}',
      url4: 'http://t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}',
      flag: 2,
      polygon: [] as any,
      mapShow: true,
      tilelayerList: JSON.parse(JSON.stringify(layerList)).slice(6, 9),
      layerId: '',

      paramList: [{ id: 't2m', text: '温度', unit: '℃' }, { id: 'rain', text: '降水', unit: 'mm' }],
      paramIndex: 1,
      timeId: 'hour',
      currentTimeObj: {} as any,
      minMaxLonLat,
      intervalNum: 5,
      gridData: {
        data: [],
        latsize: 0,
        lonsize: 0,
        startlat: 0,
        startlon: 0,
        nlon: 0,
        nlat: 0
      },
      gridDataArray: [],
      polylineArr: [] as any,
      changeLonLat: [config.defaultStationObj.lat, config.defaultStationObj.lon],
      currentLng: config.defaultStationObj.lon,
      currentLat: config.defaultStationObj.lat,
      showTable: true,
      showChart: true,
      forecastArray: [],
      oildepotMarker: [] as any,
    }
  },
  mounted() {
    this.oildepotMarker = [
      {
        name: '陕西延长石油（集团）有限责任公司管道运输第五分公司输油末站',
        // name: '陕西延长石油第五分公司',
        imgIcon: '/static/image/monitor/legend_3.png',
        lon: 109.13278330776976,
        lat: 34.3380605877569,
      },
    ]
    this.changeLonLat = [this.oildepotMarker[0].lat, this.oildepotMarker[0].lon]
    this.currentLng = this.oildepotMarker[0].lon
    this.currentLat = this.oildepotMarker[0].lat
    this.polygon = polygonService.polygon('610115')[0]
    this.initPolyline()
    this.forecastArray = []
    if (this.timeId === 'hour') {
      this.getHourForcast()
    } else {
      this.getDayForcast()
    }
  },
  methods: {
    currentTime(obj: any) {
      this.currentTimeObj = obj
      if (Object.keys(this.minMaxLonLat).length > 0) {
        this.getGridPoint()
      }
    },
    changeParam(index: number) {
      this.paramIndex = index
      this.getGridPoint()
    },
    changeTime(id: any) {
      this.timeId = id
      this.forecastArray = []
      if (this.timeId === 'hour') {
        this.getHourForcast()
      } else {
        this.getDayForcast()
      }
    },
    getGridPoint() {
      // console.log(this.paramList[this.paramIndex].id)
      // console.log(this.timeId)
      request.get('/gpr/grid', this.getQueryCondition()).then((data: any) => {
        this.gridData = data
        this.againRender()
      })
    },
    againRender() {
      const gridDataArr: any = []
      // const { maxLon, minLon, maxLat, minLat } = this.minMaxLonLat
      // const intervalM = calcIntervalNum(maxLat, minLat, this.intervalNum)
      // const intervalN = calcIntervalNum(maxLon, minLon, this.intervalNum)
      const gridPointData: any = this.gridData.data
      const latsize = this.gridData.latsize
      const lonsize = this.gridData.lonsize
      const startlat = this.gridData.startlat
      const startlon = this.gridData.startlon
      const nlon = this.gridData.nlon
      const nlat = this.gridData.nlat
      for (let lonIdx = 0; lonIdx < lonsize; lonIdx++) {
        const longitude = startlon + (lonIdx * nlon)

        for (let latIdx = 0; latIdx < latsize; latIdx++) {
          const latitude = startlat + latIdx * nlat
          const dataIdx = (lonsize * lonIdx) + latIdx
          const p = gridPointData[dataIdx]
          gridDataArr.push({
            latitude,
            longitude,
            data: p,
          })
        }
      }





      // for (let m = 0; m < gridRow.length; m += this.intervalNum) {
      //   if (m > intervalM * this.intervalNum) {
      //     continue
      //   }
      //   const latitude = minLat + m * 0.025
      //   for (let n = 0; n < gridRow[m].length; n += this.intervalNum) {
      //     if (n > intervalN * this.intervalNum) {
      //       continue
      //     }
      //     const longitude = minLon + n * 0.025
      //     const p = gridRow[m][n]
      //     gridData.push({
      //       latitude,
      //       longitude,
      //       data: p,
      //     })
      //   }
      // }
      console.log(gridDataArr)
      this.gridDataArray = gridDataArr
    },
    getQueryCondition() {
      const queryDate = this.currentTimeObj.id
      let publishTime = ''
      let limit = 0
      if (this.timeId === 'hour') {
        const selectTime = '08';
        const selectDay = moment().format('YYYY-MM-DD') + ' ' + selectTime + ':00:00';
        publishTime = selectDay.substring(2, 13).replace(/\s+/g, '').replace(/-/g, '');
        const selectGetTime = new Date(Date.parse(selectDay.replace(/-/g, '/'))).getTime();
        const queryTime = queryDate.substring(0, 4) + '/' + queryDate.substring(4, 6) + '/' +
          queryDate.substring(6, 8) + ' ' + queryDate.substring(8) + ':00:00'
        const queryGetTime = new Date(queryTime).getTime()
        limit = (queryGetTime - selectGetTime) / (60 * 60 * 1000);
      } else {
        const selectDay = moment().format('YYYY-MM-DD') + ' ' + '00:00:00';
        publishTime = moment(new Date(), 'YYYYMMDD').format('YYMMDD')
        const selectGetTime = new Date(Date.parse(selectDay.replace(/-/g, '/'))).getTime();
        const queryTime = queryDate.substring(0, 4) + '/' + queryDate.substring(4, 6) + '/' +
          queryDate.substring(6, 8) + ' ' + '00:00:00'
        const queryGetTime = new Date(queryTime).getTime()
        limit = (queryGetTime - selectGetTime) / (60 * 60 * 1000 * 24);
      }
      const { maxLon, minLon: minlon, maxLat, minLat: minlat } = this.minMaxLonLat
      // 根据最大值和最小值，计算满足不同缩放比例的间隔的最大值
      const maxlat = calcRelativeMax(maxLat, minlat)
      const maxlon = calcRelativeMax(maxLon, minlon)
      const queryCondition = {
        minlon, maxlon, minlat, maxlat,
        element: this.paramList[this.paramIndex].id, pulishtime: publishTime, timesession: limit
      }
      return queryCondition
    },
    initPolyline() {
      const { maxLon: maxLonTmp, minLon, maxLat: maxLatTmp, minLat } = this.minMaxLonLat
      const maxLon = calcByZoom(maxLonTmp, minLon, this.intervalNum)
      const maxLat = calcByZoom(maxLatTmp, minLat, this.intervalNum)
      const polylineArr: any = getPolylineArray({ maxLon, minLon, maxLat, minLat }, this.intervalNum)
      this.polylineArr = polylineArr
    },
    getIntervalNum(zoomIndex: number) {
      let numInterval;
      if (zoomIndex <= 7) {
        numInterval = 12;
      } else if (zoomIndex <= 8) {
        numInterval = 8;
      } else if (zoomIndex <= 9) {
        numInterval = 5;
      } else if (zoomIndex <= 10) {
        numInterval = 3;
      } else if (zoomIndex <= 11) {
        numInterval = 2;
      } else {
        numInterval = 1;
      }
      this.intervalNum = numInterval
    },
    zoomUpdated(zoom: number) {
      this.zoom = zoom
      this.getIntervalNum(zoom)
      this.initPolyline()
      this.againRender()
    },
    changeLayer(id: any) {
      // console.log(id)
      this.flag = id
      this.tilelayerList.map((e: any) => {
        if (e.id === id) {
          e.checked = true
        } else {
          e.checked = false
        }
      })
    },
    mouseEnter(id: any) {
      this.layerId = id
    },
    mouseLeave() {
      this.layerId = 'null'
    },
    enlarge() {
      this.zoom += 0.2
    },
    narrow() {
      this.zoom -= 0.2
    },
    markerChange(mapObj: any) {
      this.changeLonLat = [parseFloat(mapObj.latlng.lat.toFixed(4)), parseFloat(mapObj.latlng.lng.toFixed(4))]
      this.currentLng = mapObj.latlng.lng
      this.currentLat = mapObj.latlng.lat
      this.showTable = true
      this.showChart = true
      // console.log(mapObj.latlng.lat)
      // console.log(mapObj.latlng.lng)
      this.forecastArray = []
      if (this.timeId === 'hour') {
        this.getHourForcast()
      } else {
        this.getDayForcast()
      }
    },
    getHourForcast() {
      weatherData.hourWeatherData(this.currentLng, this.currentLat).then((data) => {
        // console.log(data)
        const current = moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHH0000')
        let num = 0
        data.map((e: any, index: any) => {
          const wp = e.wp < 10 ? 'd0' + e.wp : 'd' + e.wp
          e.date = moment(e.dataTime, 'YYYYMMDDHHmmss').format('MM/DD')
          e.time = moment(e.dataTime, 'YYYYMMDDHHmmss').format('HH:mm')
          e.wpimg = `/static/weatherIcon/${wp}.png`
          e.wpdesc = skyconText.weatherIcon(wp)
          if (e.dataTime === Number(current)) {
            // console.log(e)
            num = index
          }
        })
        this.forecastArray = data.slice(num, num + 12)
        // console.log(this.forecastArray)
      })
    },
    async getDayForcast() {
      let wpdata = [] as any
      await weatherData.dayWeatherWp(this.currentLng, this.currentLat, 20).then((res) => { wpdata = res })
      weatherData.dayWeatherData(this.currentLng, this.currentLat, 10).then((data) => {
        // console.log(data)
        data.map((e: any, index: any) => {
          const t1 = moment().add(index, 'd').format('YYYYMMDD')
          const t2 = moment().add(index + 1, 'd').format('YYYYMMDD')
          if (!wpdata || wpdata.length === 0) { return }
          let wp1 = null
          let wp2 = null
          wpdata.map((i: any) => {
            const { dataTime, wp12 } = i
            if (dataTime === `${t1}200000`) { wp1 = wp12 }
            else if (dataTime === `${t2}080000`) { wp2 = wp12 }
          })
          e.wp = [wp1, wp2]
        })
        data.map((e: any) => {
          // const wp = e.wp24 < 10 ? 'd0' + e.wp24 : 'd' + e.wp24
          // e.wpimg = `/static/weatherIcon/${wp}.png`
          // e.wpdesc = skyconText.weatherIcon(wp)
          e.date = moment(e.dataTime, 'YYYYMMDDHHmmss').subtract(1, 'days').format('MM/DD')
          const str1 = !e.wp[0] && e.wp[0] !== 0 ? '--' : e.wp[0] < 10 ? `d0${e.wp[0]}` : `d${e.wp[0]}`
          const str2 = !e.wp[1] && e.wp[1] !== 0 ? '--' : e.wp[1] < 10 ? `d0${e.wp[1]}` : `d${e.wp[1]}`
          const desc1 = e.wp[0] === 0 ? skyconText.weatherIcon(str1) : !e.wp[0] ? '--' : skyconText.weatherIcon(str1)
          const desc2 = e.wp[1] === 0 ? skyconText.weatherIcon(str2) : !e.wp[1] ? '--' : skyconText.weatherIcon(str2)
          e.wpdesc = desc1 === desc2 ? desc1 : `${desc1}转${desc2}`
          e.wpimg = e.wp[0] === 0 ? `/static/weatherIcon/d0${e.wp[0]}.png` : !e.wp[0] ? '/static/weatherIcon/--.png' : e.wp[0] < 10 ? `/static/weatherIcon/d0${e.wp[0]}.png` : `/static/weatherIcon/d${e.wp[0]}.png`
        })
        this.forecastArray = data.slice(1, 9)
        // console.log(this.forecastArray)
      })
    },
  },
})
